import React from "react"

import { footnote, footlink } from "../shared/links"

import styles from "../../styles/main.module.css"

export default function SkinInTheGame() {

  const footnotes = [
    "I sometimes wondered if this was the effect Twitter had on this man. Reading Black Swan it was already apparent to me that this is partly his personality, but it felt much more intense here than in the Black Swan. I’m not sure why, though I have my theories.",
    "Since I have not experienced the same life as NNT I often do not understand the source of his anger and have reasons why it would seem misplaced and “cab-driver-like.” That said, rather than “refuting” it I think it makes more sense to simply ignore it and accept it as “where he’s coming from,” since I would be surprised if it was not quite reasonable in various places even if it did not seem so to me.",
    "To learn how to avoid making mistakes systems must have feedback loops. Skin in the game is effectively this very notion—that if you are making a mistake you should know. Knowing happens when you experience pain at your loss.",
    "Consider the financial crisis of 2008 for the former and the invasion of Iraq for the latter. There are more examples, though.",
    "There are other explorations of skin in the game: CEOs vs entrepreneurs, the bible and rules for trade in islamic countries, virtue signaling, authenticity and Trump’s election, expert problems in economics, and more. I will not go into all of them. The book is worth reading.",
    "People like Richard Thaler.",
    " You can think of ruin as “death” in whatever game or situation is being considered. Once you are ruined you cannot continue. In a casino where you cannot take on debt, this would be when you run out of money. Similarly, in life this would literally be dying.",
    "They’ll use mathematical arguments which ignore the path dependence, supposedly. Assuming a linearity of some expectations in certain situations I believe would be on such erroneous argument. (i.e. you can’t just sum over various paths since paths that take certain different twists and turns will end earlier, so you can’t just move things around willy nilly).",
    "He often repeats that economists are bad with “things that move.”",
    "NNT also hates these people because they tend to believe in top-down approaches (which usually do not work) rather than bottom-up approaches which are less “elegant” (platonic) but work with time and skin in the game. They tend to be annoying and stick around because they have no skin in the game and so don’t learn and don’t get removed soon enough for NNT’s liking.",
    "He also has a compelling argument for how to live when there are many “layers” of the self. We are at the same time a body, and part of a family (hopefully), of a society, of a species, of a planet, etcetera. In this holonic view, there are different costs to the ruin of each one. We ought to take more risks that are non-ruinous (i.e. ruinous for a very low level system—imagine something potentially ruinous for some cells like hard exercise is good for the greater system to avoid ruin: the body), and avoid the ruinous risks for bigger systems.",
  ]

  return (
    <React.Fragment>
      <h2>A Worthwhile Rant</h2>
      <hr className={styles.top_seperator} />
      <p>Skin In the Game is Nassim Nicholas Taleb’s newest addition to the incerto (at least informally). It is a mixed bag in my opinion. As food for thought, it’s excellent. There are many ideas explored only a little bit which are very compelling (especially in real life). On the other hand, the pacing and emotional stance of the book is a bit off for someone who wishes to think more deeply. As for pacing, I understand that NNT is more interested in avoiding “platonicity” which I believe to be a likely side effect of thinking “deeply,” but some platonicity can be helpful if you understand it for what it is (and do not mistake it for reality). Basically, my small gripe is that Skin in The Game reads a bit schizophrenically, as every topic has tons of examples all presented rather quickly. I would like to have some more time to mill each example instead. As for “emotional stance” what I simply mean is that NNT is angry—very angry {footlink(1)}. He sometimes reads like an old cab driver who hates the “system” and whatnot. Whether he is justified or not (he probably is somewhat justified), it can be a little off putting (though at times it’s fun to watch him go). With that said, these are two easy issues to fix: read the book slowly (taking breaks and thinking), and get used to exploring his anger and ignoring it if you don’t understand it {footlink(2)}. Overall, the ideas make up for these factors, making it a worthwhile read if you are willing to take the core concepts to heart.</p>
      <p>The thread which ties the book together is that of “skin in the game.” Skin in the game means walking the walk if you talk the talk. Skin in the game means no cheap talk—you should do badly if you give bad advice. Skin in the game means symmetry in law and justice. Skin in the game is necessary for self-perpetuating systems, and those in which skin in the game is lacking often die out or violently excise those within who do not have it {footlink(3)}. NNT explores how skin in the game is present in the laws of hammurabi, efficient markets, and talmudic laws, and how it is missing in our current banking and political systems {footlink(4)} has wrought dangerous consequences, with yet more terrifying risks to come. He also delivers scathing remarks for journalism in the modern era. According to NNT most of journalism is “cheap talk” which spreads misinformation and fosters a myopic 24-hour news-cycle-style view of the world (though there are real journalists who should be greatly respected) {footlink(5)}. He also touches on some other themes, including how minorities run the world (not majorities), and ruin in probabilistic systems (my two favorite).</p>
      <p>With respect to minorities running the world, NNT explores how ensembles are non-linear relative to their components. The most common case for minorities running the world is where the majority is relatively ambivalent (maybe has a slight preference), but a small minority has a strong preference. For example, all juices are kosher in the US. This is because most people do not mind paying a couple more cents for kosher juice, while a few people will not drink any juice if it’s not kosher. The most thought-provoking exploration of this dynamic for me in Skin in The Game was regarding Christianity and Islam. NNT suggests that Christianity succeeded particularly because it was intolerant (and so did Islam). He warns us that tolerance of fundamentalist Islamic beliefs is self-destructive because of this dynamic, and is probably right in that regard.</p>
      <p>With respect to ruin in probabilistic systems, his writing should be understood in the context as a kickback against the “nudge boys” (as I think he refers to them, somewhat pejoratively; we will take this term in a more neutral sense). The nudge boys are a group of economists {footlink(6)} and (usually) rationalists who believe that humans tend to be risk averse and irrational, and that the right response is to “nudge” them to do the “right” things to “maximize” (expected) utility. NNT’s view is that the nudge boys are not only annoying, but bad at math. Basically, in many situations analyzed by these economists there is path dependence on your actions, since some actions may lead to what is called “ruin” {footlink(7)}. The nudge boys tend to ignore this {footlink(8)} and overestimate the rewards from certain actions, promoting strategies which have high expected value but also a high chance of ruin. He gives various examples. He defends various superstitions of the more paranoiac kind such as one of a specific native tribe (on a certain oceanic island) of never sleeping under dead trees, holding that they are not risk averse, but employing a ruin-avoiding strategy. If you avoid ruin, your expected value is unbounded since you get an arbitrarily long amount of time. Being “high EV” in accordance to the nudge boys often is fundamentally misunderstanding the interplay of time and ruin in probabilistic systems {footlink(9)}. If we were to do as the nudge boys asked we’d be increasing our chances of ruin and being weeded out by time. Other examples include stocks and trading. PS {footlink(10)} PPS {footlink(11)}</p>
      {footnotes.map((note, index) => footnote(index + 1, note))}
      <div className={styles.phone_home}>
        <a href="/">Click me to return home.</a>
      </div>
    </React.Fragment>
  )
}
